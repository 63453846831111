@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");

.section-title {
  font-weight: bold;
  /* height: 34px; */
  background: #8c949d;
  font-size: 12px;
}

* {
  font-family: "Lato", sans-serif;
  box-sizing: border-box;
}

img {
  max-width: 100%;
  height: auto;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.header.navbar {
  padding-top: 0px;
  padding-bottom: 0px;
}

.flex-shrink {
  flex-shrink: 0;
}

.flex-expand {
  flex: 1 0 auto;
}

.pointer {
  cursor: pointer;
}

.o-5 {
  opacity: 0.5;
}

.container {
  padding: 3em 0em;
}

.dashboard {
  display: flex;
  flex-direction: row;
}

.card {
  width: 180px;
  margin-right: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.name-header {
  /* margin-left: 50em; */
}
.category {
  overflow-x: auto;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 15px;
}

.category::-webkit-scrollbar {
  display: none;
}

.category-label {
  padding: 0em 0em 1em 1em;
  font-weight: bold;
  font-size: 22px;
}

.react-multiple-carousel__arrow--left {
  left: calc(0% + 1px);
}

.react-multiple-carousel__arrow--right {
  right: calc(0% + 1px);
}

.main-head {
  height: 150px;
  background: #fff;
}

.header-logo {
  width: 24px;
  height: 23px;
  cursor: pointer;
}

.sidenav {
  height: 100%;
  background-color: #000;
  overflow-x: hidden;
  padding-top: 20px;
}

.main {
  padding: 0px 10px;
}

.content-main {
  display: flex;
  height: 100%;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.4;
}

.disabled svg {
  pointer-events: none;
}

@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
}

@media screen and (max-width: 450px) {
  .login-form {
    margin-top: 10%;
  }
  .register-form {
    margin-top: 10%;
  }
}

.login-main-text {
  margin-top: 20%;
  padding: 60px;
  color: #fff;
}

.login-main-text h2 {
  font-weight: 300;
}

.p-25 {
  width: 25%;
}

.p-l-2 {
  padding-left: 2em;
}

.p-b-2 {
  padding-bottom: 2em;
}

.subitem {
  font-size: 12px;
}

.btn-action {
  width: 45%;
}

.form-control {
  font-size: 0.8rem !important;
}
.form-control-sm {
  font-size: 0.7rem !important;
}

.form-check .form-check-input[type="checkbox"] {
  border-radius: 0;
}

.form-check.form-check-inline {
  font-size: 0.8rem;
}

/* Cart styles */
.cart {
  max-height: 214px;
  overflow-y: auto;
  height: 100%;
  position: relative;
}

.table--cart {
  max-height: 600px;
  overflow-y: auto;
  margin: 0 !important;
}

.table--cart .table > :not(caption) > * > * {
  padding: 0.4rem;
}

.table > :not(caption) > * > * {
  padding: 0.1rem 0.5rem !important;
}

.table--cart thead {
  position: sticky;
  top: 0px;
}
.table--cart thead tr:first-child {
  background-color: #b5babf;
}

.table--cart th,
.table--cart td {
  vertical-align: middle;
  user-select: none;
  font-size: 0.8rem;
}

.table--cart td {
  min-height: 50px;
}

.product-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.product-info img {
  margin-right: 0.5em;
}

.product-qty {
  display: flex;
  align-items: center;
}

.product-qty span {
  display: block;
  margin: 0 1em;
}

.product-qty svg {
  cursor: pointer;
}

.action-cell {
  display: flex;
  align-items: center;
  /* justify-content: flex-end; */
}

.btn.action-btn-table {
  width: fit-content;
  height: fit-content;
  padding: 0.2rem;
  border-radius: 50% !important;
  background-color: transparent !important;
  border: none;
  font-size: 18px;
}
.btn.action-btn-table.btn-secondary {
  color: #000 !important;
}
.btn.action-btn-table.btn-danger {
  color: red !important;
}

/* Bill styles */
.bill {
  background: #babfc4;
}

.table-hight {
  height: 128px;
}
.table-hight-change {
  height: 180px;
}

.row--total {
  border-top: 1px dashed #8c949d;
}

.row--total td h4 {
  font-weight: bold;
  font-size: 1.5em;
  margin: 0;
}

.bill__actions {
  display: flex;
}

.bill__actions .btn {
  padding: 0.8em;
  font-weight: bold;
  border-radius: 0;
}

/* Product Off-Canvas */
.product-offcanvas .offcanvas-body {
  padding-bottom: 0;
}

.product-offcanvas .product-offcanvas__form {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0;
}

.product-offcanvas__form .form__body {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.product-offcanvas__form .form__body .commentText {
  max-height: 100px;
  border-radius: 0;
}

.product-offcanvas .form__actions {
  display: flex;
  margin-left: 0px;
}

.product-offcanvas .form__actions button.btn {
  padding: 0.8em;
  font-weight: bold;
  border-radius: 0;
}

.product-offcanvas .offcanvas-header {
  padding: 0.5em;
  background-color: #babfc4;
}

/* Selections */

/* .selection {
  max-width: 100px;
} */

.selection > label {
  color: #b54400;
  font-weight: bold;
  font-size: 14px;
}

.radio-group {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.radio-group .custom-radio:not(:last-child) {
  margin-right: 0.5em;
}

.custom-radio label {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  width: 35px;
  height: 35px;
  border-radius: 50%;
  font-size: 12px;
  position: relative;
  border: 1px solid #b54400;
  color: #b54400;
  cursor: pointer;
}

.custom-radio input {
  display: none;
}

.custom-radio--square label {
  width: calc(100% + 3px);
  height: 30px;
  border-radius: 0;
  padding: 0.5em;
}

.custom-radio--square input[type="radio"]:checked + label {
  height: 28px;
}

.custom-radio input[type="radio"]:checked + label {
  background: #b54400;
  outline: 1px solid #b54400;
  font-weight: bold;
  border: 3px solid #fff;
  color: #fff;
}

.custom-radio.rose label {
  border: 1px solid #b91e5c;
  background: #b91e5c;
}

.custom-radio.rose input[type="radio"]:checked + label {
  outline: 1px solid #b91e5c;
  background: #b91e5c;
}

.custom-radio.yellow label {
  border: 1px solid #f7c74d;
  background: #f7c74d;
}

.custom-radio.yellow input[type="radio"]:checked + label {
  outline: 1px solid #f7c74d;
  background: #f7c74d;
}

.custom-radio.silver label {
  border: 1px solid #f6efef;
  background: #f6efef;
}

.custom-radio.silver input[type="radio"]:checked + label {
  outline: 1px solid #f6efef;
  background: #f6efef;
}

.custom-radio.white label {
  border: 1px solid #ebebeb;
  background: #ebebeb;
}

.custom-radio.white input[type="radio"]:checked + label {
  outline: 1px solid #ebebeb;
  background: #ebebeb;
}

.custom-radio.platinum label {
  border: 1px solid #ebebeb;
  background: #ebebeb;
}

.custom-radio.platinum input[type="radio"]:checked + label {
  outline: 1px solid #f1f1f1;
  background: #f1f1f1;
}

.selection-header-text {
  text-transform: uppercase;
  color: #fff;
}

.dashboard-user-name {
  font-weight: bold;
  font-size: 18px;
  text-align: center;
}
.dashboard-user-name span {
  font-size: 16px;
}
.selection-header-text span {
  text-transform: none;
}

.selection--general-details .detail {
  display: flex;
  align-items: center;
  margin-bottom: 0.5em;
}

.selection--general-details .detail label {
  color: #b54400;
  font-weight: bold;
  font-size: 14px;
  width: 80px;
  font-size: 14px;
}

.selection--general-details .detail p {
  margin: 0;
  font-weight: bold;
  font-size: 14px;
}

/* Login Styles */
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f5f5f5;
}

.login-form {
  width: 25%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.login-form form {
  margin-top: 70px;
  width: 100%;
  display: block;
  padding: 50px;
  border-radius: 20px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
  opacity: 1;
  background-color: rgba(255, 255, 255, 1);
}

.form-group:not(:last-of-type) {
  margin-bottom: 1em;
}

.form-group label {
  font-weight: bold;
}

.form-group input {
  border-radius: 5px;
  border: 1px solid rgba(151, 151, 151, 1);
  opacity: 0.7;
  padding: 1em;
  margin-top: 0.2em;
}
.form-group select {
  border-radius: 5px;
  border: 1px solid rgba(151, 151, 151, 1);
  opacity: 0.7;
  padding: 1em;
  margin-top: 0.2em;
  font-size: 13px;
}
.form-group select option {
  font-size: 16px;
}
.btn[type="button"] {
  font-weight: bold;
  text-transform: uppercase;
}

.login-form .btn.login-button {
  width: 100%;
  font-weight: bold;
  text-transform: uppercase;
  color: white;
}

@media screen and (max-width: 1200px) {
  .login-form {
    width: 45%;
  }
}

@media screen and (max-width: 1024px) {
  .login-form {
    width: 40%;
  }
}

@media screen and (max-width: 768px) {
  .login-form {
    width: 60%;
  }

  .login-form form {
    padding: 30px;
  }
}

@media screen and (max-width: 480px) {
  .login-form {
    width: 100%;
  }

  .login-form form {
    padding: 20px;
  }
}

/* Dashboard Container */
.dashboard-container {
  /* display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative; */
}

.dashboard-container .logo {
  /* position: absolute; */
  /* top: 80px; */
  /* left: 20px; */
  /* width: 10%; */
}

.dashboard-link-wrap {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-items: center;
  gap: 20px;
  /* justify-content: center; */
}

.dashboard-link-wrap hr {
  /* width: 100%;
  border: none;
  background: none;
  margin: 0;
  padding: 0; */
}

.dashboard-link {
  padding: 20px 20px;
  width: 100%;
  /* margin-right:   20px; */
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: #f5f5f5;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  flex-direction: column;
  color: black;
  font-size: 1.1em;
}

/* @media screen and (max-width: 700px) {
  .dashboard-link-wrap {
    grid-template-columns: repeat(2, 1fr);
  }
} */

@media screen and (max-width: 1200px) {
  .dashboard-link-wrap {
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
  .dashboard-link {
    /* width: 200px; */
    padding: 20px 30px;
    font-size: 0.8em;
  }
}

.dashboard-link-btn {
  padding: 10px 20px;
  width: 120px;
  margin-right: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  text-transform: uppercase;
  font-weight: bold;
  text-decoration: none;
  flex-direction: column;
  color: black;
  font-size: 1.3em;
  cursor: pointer;
}

.dashboard-link .dashboard-link-img {
  border-radius: 50%;
  background: white;
  padding: 10px;
  width: 50px;
  height: 50px;
  margin-bottom: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dashboard-link .dashboard-link-img img {
  max-width: 80%;
}

/* Product Card */
.product-card {
  cursor: pointer;
}

.list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, auto));
  grid-template-rows: auto auto;
  /* grid-auto-flow: column; */
  gap: 5px;
  /* overflow-y: scroll; */
  /* height: 30%; */
  /* max-height: 500px; */
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 5px;
  padding-left: 15px;
  background-color: #f5f5f5;
  height: 300px;
  overflow-y: scroll;
}

/* .grid-column {
  grid-auto-flow: column;
}
.grid-row {
  grid-auto-flow: row;
} */

.list::-webkit-scrollbar {
  display: none;
}

.card.list-card {
  width: 200px;
  min-height: 166px;
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #ececec;
  border-radius: 6px;
  font-size: 0.8em;
  position: relative;
}

.card.list-card.sold-out {
  filter: grayscale(1);
  border: 1px solid #cccccc33;
  pointer-events: none;
  opacity: 1;
  position: relative;
}

.product-card-wrapper {
  opacity: 1;
}

.list-card .sold-out-badge {
  position: absolute;
  top: 10px;
  right: 10px;
}

.list-card img {
  max-width: 80px;
  max-height: 80px;
  margin-top: 1em;
  text-align: center;
}

.list-card .card-body {
  margin-top: auto;
  flex: 0;
}

.list-card .card-body p {
  margin: 0;
}

.list-row {
  display: flex;
  padding-top: 0em;
  padding-left: 2em;
}

/* Category Tabs */
.nav-tabs.category-tabs {
  padding-left: 20px;
}

.card.category-card {
  width: 180px;
  height: 80px;
  border-radius: 6px;
  border: 1px solid #ececec;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center top;
  position: relative;
  overflow: hidden;
}

.category-card:before {
  content: "";
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000077;
  position: absolute;
  z-index: 0;
}

.category-card.selected-category {
  border-color: #ddbc47;
  font-weight: bold;
  border-width: 3px;
}

.category-card.selected-category p {
  color: #ddbc47;
}

.category-card .card-body {
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  letter-spacing: 3px;
  position: relative;
  z-index: 1;
}

.category-card p {
  margin: 0;
  color: white;
}

/* Nav tabs */
.category-tabs.tab-content {
  border: 1px solid red;
}

.category-tabs .nav-link {
  color: black;
  font-size: 12px;
}

.category-tabs .nav-link:hover {
  color: #ddbc47;
}

.category-tabs.nav-tabs .nav-link.active {
  color: #ddbc47;
  font-weight: bold;
}

/* Preloader */
.loader-wrapper-2 {
  position: absolute;
  z-index: 1046;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000011;
}
.loader-wrapper {
  position: fixed;
  z-index: 1046;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #00000088;
}

.loader {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #ddbc47;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.error {
  font-size: 13px;
  color: #ff000099;
  font-weight: bold;
  margin-top: 5px;
}

/* Settle */
.settle {
  flex: 1;
  height: 100%;
  display: flex;
}

.settle .container-fluid {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.settle .main--row {
  flex: 1;
  margin-bottom: -70px;
}

.settle .main--row div[class^="col-"] {
  display: flex;
  padding: 0;
  flex-direction: column;
}

.settle--cost-info {
  background-color: #e4e6e8;
  flex: 1;
}

.settle .action--col {
  display: flex;
  padding: 10px 15px;
  justify-content: center;
}

.settle .action--col button {
  padding: 1em;
  flex: 1;
  color: black;
  position: relative;
}
.settle .action--col button a {
  color: black;
  text-decoration: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.settle .action--col button:not(:last-of-type) {
  margin-right: 1em;
}

.settle .action--row .col-sm-12 button {
  border-radius: 0;
}

/* Payment Type */
.settle--payment-type {
  background-color: #f5f5f5;
  display: flex;
  flex: 1;
  padding: 40px 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.payment-mode {
  width: 180px;
  height: 100px;
  text-align: center;
  margin-bottom: 1em;
  margin-right: 1em;
  border: 2px solid #747d88;
  color: #747d88;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
}

.payment-mode.selected {
  color: #000000;
  border-color: #000000;
  background-color: #747d88;
}

/* Payment details */

.settle--payment-details {
  background-color: #f1f2f3;
  flex: 1;
  padding: 1em;
}

.settle--payment-details .single-payment {
  display: flex;
  align-items: center;
}

.settle--payment-details .single-payment h5 {
  margin: 0;
}

.settle--payment-details .single-payment p {
  margin: 0;
  margin-left: auto;
  margin-right: 1em;
}

/* Tender Modal */
.tender-modal .modal-content {
  border-radius: 0;
}

.tender-modal .tender-modal__body {
  padding: 40px 60px;
}

.tender-modal .tender-modal__actions {
  display: flex;
}

.tender-modal .tender-modal__actions button {
  flex: 1;
  border-radius: 0;
  padding: 25px 0;
}

.amount-display {
  background-color: #b5babf;
  padding: 25px;
  text-align: center;
  font-weight: bold;
  font-size: 1.2em;
}

.tender-modal .calculator .btn-row {
  display: flex;
}

.tender-modal .calculator .btn-row:not(:last-of-type) {
  margin-bottom: 0.5em;
}

.tender-modal .calculator button {
  border: none;
  color: white;
  flex: 1;
  padding: 15px;
  border-radius: 0;
  font-size: 1.1em;
  text-transform: uppercase;
}

.tender-modal .calculator button:not(:last-of-type) {
  margin-right: 0.5em;
}

/* Customer details modal */
.customer-detail-modal .modal-footer {
  padding: 0;
  border: none;
}

.customer-detail-modal .modal-footer button {
  flex: 1;
  margin: 0;
  border-radius: 0;
  padding: 15px;
  color: black;
  font-weight: 900;
}
.customer-detail-modal .modal-footer button.btn-secondary {
  color: #fff;
}

/* Orders table */
.table--orders thead td {
  font-weight: 900;
}

.table--orders thead td,
.table--orders tbody td {
  padding: 0.6em !important;
}

.table--orders.sunmi thead td {
  font-weight: 700;
}

.table--orders.sunmi thead td,
.table--orders.sunmi tbody td {
  padding: 0.6em !important;
  font-size: 14px;
}

/* Invoices */
.invoice-print {
  padding: 0 15px;
}
.invoice-footer {
  position: fixed;
  bottom: 10px;
  left: 50%;
  width: 100%;
  text-align: center;
  transform: translateX(-50%);
  font-size: 12px;
  page-break-after: always;
}
.invoice-footer > hr {
  color: rgb(172, 169, 169);
  margin-inline: 20px;
}

.invoice-to .invoice-item {
  display: flex;
  margin-bottom: 1em;
}

.invoice-to .invoice-item strong {
  width: 250px;
  height: auto;
  font-size: 12px;
}
.invoice-to .invoice-item p {
  font-size: 12px;
}
.invoice-product-detail > .subitem {
  font-size: 12px;
}

.invoice-products {
  /* border-top: 1px solid gray; */
}

.table--products thead {
  background: #f3ab00;
}

.table--products > thead > td {
  color: white !important;
  font-weight: bold;
}

.table--products th,
.table--products td {
  padding: 1em !important;
}

.table--products > tbody > tr > td {
  font-size: 12px;
}
.table--products .total {
  text-align: right;
  font-size: 12px;
}
.table--products .total > strong > span {
  text-transform: uppercase;
}

.invoice-title {
  font-weight: 900;
  background-color: #f3ab00;
  padding: 12px;
  color: #fff;
  font-size: 28px;
}

.invoice-section-title {
  font-weight: 700;
}

.border-container-with-title {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 20px;
}
.border-container-with-title-span {
  font-weight: bold;
  font-size: 14px;
}

.form-label {
  font-size: 13px;
}

.custom-select-search {
  position: relative;
  top: 0;
}
.custom-select-search .input-container {
  position: relative;
}
.custom-select-search .input-container > span {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-size: 18px;
  cursor: pointer;
}

.custom-select-search input:read-only {
  background-color: #fff;
}
.custom-select-search-label {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 3px;
}
.custom-select-search .select-items-container {
  position: absolute;
  left: 0px;
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
  max-height: 200px;
  background-color: #fff;
  z-index: 1;
}

.custom-select-search .select-items-container .select-items {
  display: flex;
  flex-direction: column;
  max-height: 135px;
  overflow: auto;
}
.custom-select-search > .select-items-container span {
  padding: 5px 10px;
  cursor: pointer;
}
.custom-select-search > .select-items-container > span.button {
  padding: 10px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
}
.custom-select-search > .select-items-container span:hover {
  background-color: #ccc;
}

.custom-accordion .accordion-collapse .card-body {
  display: flex;
  flex-direction: column;
}

.accordian-item-custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordian-item-custom .arrow {
  transition: 0.5s all;
}

.accordian-item-custom.active .arrow {
  transform: rotate(-135deg);
}

.arrow {
  border: solid black;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
}

.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.alert-success-btn {
  width: 100px;
}
.alert-cancle-btn {
  width: 100px;
}
.AlertDialog .modal-header h5 {
  padding-right: 30px;
}
.AlertDialog .modal-header button {
  margin: 0px;
  position: absolute;
  right: 0px;
  top: 0px;
  padding: 10px;
  /* background-color: #000; */
}

.report-title {
  display: none;
}

@print {
  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }
}
@media print {
  .daily-report {
    padding-inline: 15px;
  }

  .report-title {
    display: block;
  }
  .daily-report {
    display: block !important;
  }
  .col-md-6,
  .barcode-print {
    page-break-inside: avoid !important;
  }

  header,
  footer {
    display: none;
  }
  @page {
    margin: 0mm;
    padding: 0mm;
    size: A4 portrait;
    position: relative;
  }

  @page :footer {
    display: none;
  }

  @page :header {
    display: none;
  }

  .invoice-body {
    page-break-after: always;
  }

  .barcode-print {
    page-break-after: always !important;
  }
}

.flex {
  display: flex;
  align-items: center;
}

.custom-grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
}

.custom-grid.custom-grid-8 {
  grid-template-columns: repeat(8, 1fr);
}
.custom-grid.custom-grid-5 {
  grid-template-columns: repeat(5, 1fr);
}
.custom-grid.custom-grid-3 {
  grid-template-columns: repeat(3, 1fr);
}

.date-picker-size-sm {
  width: inherit;
}

.date-picker-size-lg {
  height: 46px;
  width: inherit;
}

.date-picker::placeholder {
  color: #000;
}
.date-picker-size-xl {
  height: 47px;
  width: inherit;
}

.stock-modal .modal-dialog .modal-content {
  height: 100%;
}

.stock-modal .stock-modal_body {
  max-height: inherit;
}

.stock-option-modal .modal-dialog .modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 65%;
}

.stock-option-btn {
  min-width: 170px;
  margin: 15px 25px;
}

.uploadImageContainer {
  position: relative;
  width: fit-content;
}

.uploadImage {
  width: 70px;
  height: 70px;
  cursor: pointer;
}
.removeImageButton {
  position: absolute;
  width: 20px;
  height: 20px;
  right: -20px;
  top: -10px;
  background: none;
  border: none;
  background-color: red;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inventory-image {
  width: 60px;
  height: 60px;
  box-sizing: border-box;
  aspect-ratio: 1/1;
}

.tabContainer {
  position: relative;
  margin-bottom: 20px;
}

.nav-pills .nav-link.custom-tab {
  background-color: transparent;
  color: #888888;
  border-bottom: 2px solid #888888;
  border-radius: 0px;
  padding: 5px 15px;
  z-index: 100 !important;
  cursor: pointer;
}
.nav-pills .nav-link.custom-tab.active {
  background-color: transparent;
  color: #ffc107;
  border-bottom: 2px solid #ffc107;
  z-index: 100 !important;
}
.nav-pills .nav-link.custom-tab-2 {
  background-color: transparent;
  color: #000;
  /* border-bottom: 2px solid #888888; */
  border-radius: 0px;
  padding: 5px 15px;
  z-index: 100 !important;
  cursor: pointer;
}
.nav-pills .nav-link.custom-tab-2.active {
  background-color: #888;
  color: #ffc107;
  border-radius: 5px;
  /* border-bottom: 2px solid #ffc107; */
  z-index: 100 !important;
}

.removeFileIcon {
  background-color: transparent;
  outline: none;
  border: none;
  color: red;
  font-size: 12px;
}

.fileItem {
  padding-block: 15px;
  display: flex;
  align-items: center;
}
.fileItem span {
  margin-right: 10px;
}

.fileDownloadIcon {
  font-size: 12px;
  color: #000;
}

.stock-label {
  font-size: 20px;
  font-weight: bold;
}
.stock-value {
  font-size: 20px;
}

.giftLabelValue {
  font-size: 14px;
  margin-bottom: 12px;
}

.column-fonts-red td {
  color: red !important;
}

.linkProductTitle {
  font-size: 20px;
  font-weight: 700;
}

.btn-linkItem {
  background-color: #000 !important;
  color: #fff !important;
  font-size: 13px !important;
  height: 32px;
  width: 130px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
}
.variantDetail {
  font-size: 14px;
}

.drop-down {
  padding: 5px 20px;
  border-radius: 5px;
  width: 100%;
}

.scrollable-table {
  position: relative;
  height: 70vh;
  overflow: auto;
}

.hyperlink {
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}

.checkbox-smalllabel > label {
  /* font-size: 12px; */
}

.customer-detail-modal .footer-modal .btn {
  width: 250px;
  margin-left: 10px;
  padding-block: 10px;
}
.customer-detail-modal .footer-modal {
  display: flex;
  justify-content: flex-end;
}

.background-grey {
  background-color: #f0f0f0;
  padding-inline: 20px !important;
  padding-block: 30px;
}
.background-white {
  background-color: #fff !important;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.btn-lowerCase {
  text-transform: capitalize !important;
}

.btn-free {
  width: fit-content;
  padding-inline: 25px !important;
  text-transform: capitalize !important;
}
.sticky-div {
  position: sticky !important;
  top: 10px;
}

.active-row {
  background-color: #ddbc47;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  top: 50%;
  left: 2px;
  transform: translateY(-50%);
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.switch input:checked + .slider {
  background-color: #2196f3;
}

.switch input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

.switch input:checked + .slider:before {
  left: calc(100% - 17px);
}

.like-datatable thead td {
  font-weight: 700;
  font-size: 15px;
}
.like-datatable tbody td {
  font-size: 15px;
}

.add-btn {
  font-size: 13px !important;
  font-weight: 700 !important;
}

.btn.btn-warning {
  /* background-color: #ddbc47 !important; */
}

.error-label {
  color: red;
  font-size: 12px;
}

.back-btn {
  outline: none !important;
  box-shadow: none !important;
  font-size: 20px !important;
}

.divider {
  height: 1px;
  background-color: #ccc;
}

.nfc-placeholder-container {
  height: 72vh;
  /* background-color: #000; */
}
.nfc-placeholder {
  width: 70%;
  height: 70%;
}

.linkitem-container {
  background-color: #f5f5f5;
  padding: 15px;
  margin-bottom: 5px;
}

.linkitem-title {
  font-size: 18px;
  font-weight: 700;
  color: #000;
}

.linkitem-subtitle {
  font-size: 14px;
}
.linkitem-subtitle-bold {
  font-size: 14px;
  font-weight: 600;
}

.productWiseItemBoldText {
  font-size: 18px;
  font-weight: 700;
  margin: 0px;
}

.link-item-modal {
  height: 70vh;
  /* background-color: #ccc; */
}

.icon-btn {
  width: 25px;
  height: 25px;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  outline: none;
  border: none;
  border-radius: 5px;
}
.icon-btn.danger {
  background-color: red;
  color: #fff;
}

.icon-btn.warning {
  background-color: #ddbc47;
  color: #fff;
}
.input-small {
  width: 100% !important;
  /* flex: 1; */
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.input-with-button {
  width: 100%;
}

.sidebar-menu {
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
  padding-block: 20px;
  height: fit-content !important;
}

.bold-font {
  font-weight: 600;
}

.custom-breadcrumb a {
  color: #000;
  text-decoration: none;
}

.selectAddNewButton {
  width: 100%;
  height: 100%;
}

.selectAddNewButtonContainer {
  padding: 0px !important;
}

.w-20 {
  width: 20%;
}

.uploadFileDiv {
  border: 2px dashed #ddbc47;
  height: 250px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.btn-action {
  padding: 0px !important;
  font-size: 12px !important;
  padding: 5px 15px !important;
}

.w-80 {
  width: 80%;
}

.table--attachment td {
  padding: 10px !important;
}

.dragActive {
  background-color: rgba(0, 0, 0, 0.2);
}

.removeBarsBtn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: fit-content !important;
  font-size: 12px !important;
}

.addBarBtn {
  text-transform: capitalize !important;
}

.btn-action {
  width: fit-content !important;
  height: fit-content !important;
}

.barcode-print {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
}

.barcode-print > div {
  border: 1px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
}
